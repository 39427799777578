.form-title {
    color: #1b3f74;
    font-size: 24px;
    font-weight: 300;
}

.header {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    color: #1b3f74;
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
  }
  .header-text {
    margin-left: 1vh;
    margin-top: 5px;
  }

  form {
      min-width: 50%;
  }