.inputField {
  border-radius: 8px;
  border: 1px solid #e0d6d7;
  outline: none !important;
  padding:26px;
}

.desktopInput {
font-size: 14px;
}

.inputField:active {
  outline: red($color: #000000);
}
.input-group-append {
  background: #fff !important;
}
.input-group-text {
  font-weight: bold !important;
  color: #1b3f74 !important;
  font-size: 14px;
  letter-spacing: -0.5px;
  background: transparent;
}

.formLabel {
  color: #1b3f74 !important;
  font-size: 14px;
  letter-spacing: -0.5px;
  display: block;
  text-align: left;
  padding-left: 1%;
  min-height: 20px;
}

.hidden {
  visibility: hidden;
}