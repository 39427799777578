@font-face {
  font-family: "Azo Sans Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Azo Sans Regular"), url("assets/fonts/AzoSansRegular.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Azo Sans Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  

}

.error {
  font-size: 12px;
  color: red;
  font-family: "Azo Sans Regular";
}

h2 {
  font-weight: 300;
  font-style: normal;
  font-size: 36px;
  letter-spacing: -0.5px;
  color: #1b3f74;
}

.container {
  background-color: #f4f4f4;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  padding: 0%;
}