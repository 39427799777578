.kyc-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #1b3f74;
    text-align: center;
    max-width: 600px;
    overflow: hidden;
  }
  
  .kyc-prog-bar {
    margin-top: 2vh;
    margin-left: 10vh;
    margin-right: 10vh;
    width: 70%;
  }
  
  .kyc-form-white {
    background-color: white;
    width: 100%;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-top: 2vh;
    margin-top: 2vh;
  }
  
  