.inputField {
    border-radius: 8px;
    border: 1px solid #e0d6d7;
    border-right: 0;
    outline: none !important;
  }
  .inputField:active {
    outline: red($color: #000000);
  }
  .input-group-append {
    background: #fff !important;
  }
  .input-group-text {
    font-weight: bold !important;
    color: #1b3f74 !important;
    font-size: 14px;
    letter-spacing: -0.5px;
    background: transparent;
  }

  .hidden {
    visibility: hidden;
  }

  .formLabel {
    color: #1b3f74 !important;
    font-size: 14px;
    letter-spacing: -0.5px;
    display: block;
    margin-top: 16px;
  }