.MyButton {
  height: 60px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 8px;
  border: 0;
  display: block;
  font-weight: 400;
  font-style: normal;
  font-size: medium;
  outline: none;
  color: #fff;
  min-width: 150px;
}
.MyButton:focus {
  outline: none;
}
.display-block {
  display: block;
  width: 100%;
}
.small {
  height: 44px;
}
.grey {
  background: #efefef;
}
.primary {
  background-color: #88bc08;
  color: "#FFF";
}
.white {
  background-color: white;
  border: 1px solid #dcdee4;
  color: #1b3f74;
  font-size: 18px;
  font-weight: 500;
}
.mybutton-active {
  background: #fff;
  border: 2px solid #54c0ee;
  outline: none;
}
.mybutton-active:focus {
  outline: none;
}

.disabledMyButton {
  background: rgb(192, 188, 188);
  color: black;
}
