.progress-bar-line {
    height: 2px;
    margin-top: 9px;
    background-color: #f4f4f4;

  }
  .progress-bar {
    background-color: #88bc08;
  }
  
  .progress-bar-container {
    background-color: #e8e8e8;
    border-radius: 4px;
    margin: 0px;
    padding-left: 10px;
    padding-right: 0px;
    line-height: initial;
    padding-right: 15px;
  }
