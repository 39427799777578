.textLeft {
    text-align: left;
}
.consentCard {
    border: 0px;
}

.text {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-style: normal;
    color: #1b3f74;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
